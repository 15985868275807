import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/ModalWrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between mb-6 px-6 pt-6" };
const _hoisted_2 = { class: "text-sm uppercase text-gray-100 font-semibold flex-grow" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
  key: 0,
  class: "grow flex items-center justify-center -mt-6"
};
const _hoisted_5 = { key: 1 };
const _hoisted_6 = {
  key: 0,
  class: "px-6"
};
const _hoisted_7 = /* @__PURE__ */ _createElementVNode("div", { class: "pb-6" }, null, -1);
export default /* @__PURE__ */ _defineComponent({
  __name: "Modal",
  props: {
    lg: Boolean,
    md: Boolean,
    sm: Boolean,
    noPadding: Boolean,
    isAlwaysOpen: Boolean,
    hideCloseButton: Boolean
  },
  emits: ["modal:closed"],
  setup(__props, { emit }) {
    const props = __props;
    const slots = useSlots();
    const { width } = useWindowSize();
    const classes = computed(() => {
      const result = [];
      if (props.sm) {
        result.push("sm:min-w-md sm:max-w-md");
      } else if (props.md) {
        result.push("md:min-w-lg md:max-w-lg", "md:min-w-2xl lg:max-w-2xl");
      } else if (props.lg) {
        result.push("max-w-lg", "lg:max-w-3xl");
      } else {
        result.push("max-w-lg", "lg:max-w-4xl");
      }
      return result.join(" ");
    });
    function handleClose() {
      if (!props.isAlwaysOpen) {
        emit("modal:closed");
      }
    }
    watchDebounced(
      width,
      (newWidth, oldWidth) => {
        if (oldWidth && newWidth >= 640) {
          handleClose();
        }
      },
      { debounce: 200, immediate: true }
    );
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppSpinner = __nuxt_component_1;
      const _component_BaseModalWrapper = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_BaseModalWrapper, _mergeProps({
        class: ["relative mx-auto sm:rounded-lg bg-gray-850 max-sm:h-full max-sm:max-w-full max-sm:w-full", _unref(classes)],
        "wrapper-class": "backdrop-filter backdrop-blur bg-gray-900 bg-opacity-90 max-sm:z-40"
      }, _ctx.$attrs, { onClose: handleClose }), {
        default: _withCtx(({ close, showLoading }) => [
          _createElementVNode("div", {
            class: _normalizeClass({
              "min-h-[320px] flex flex-col": showLoading
            })
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "title")
              ]),
              !__props.hideCloseButton ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_BaseIcon, {
                  name: "close",
                  class: "ml-auto h-5 w-5 min-w-5 text-gray-200 hover:text-blue-500",
                  onClick: close
                }, null, 8, ["onClick"])
              ])) : _createCommentVNode("", true)
            ]),
            showLoading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_AppSpinner, { lg: "" })
            ])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass({
                  "px-6": !__props.noPadding
                })
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2),
              _unref(slots).footer ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "footer")
              ])) : _createCommentVNode("", true),
              _hoisted_7
            ]))
          ], 2)
        ]),
        _: 3
      }, 16, ["class"]);
    };
  }
});
