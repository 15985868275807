import { default as __nuxt_component_0 } from "/opt/build/repo/components/Layout/Nav/Item.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Layout/Nav/ItemDummy.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Layout/Nav/HoverMenu.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Layout/Nav/Mobile.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "block flex-1 lg:flex" };
const _hoisted_2 = { class: "font-semibold text-base text-white" };
const _hoisted_3 = { class: "text-sm text-gray-500 group-hover:text-gray-100 mt-1" };
const _hoisted_4 = { class: "font-semibold text-base text-white" };
const _hoisted_5 = { class: "text-sm text-gray-500 group-hover:text-gray-100 mt-1" };
const _hoisted_6 = { class: "font-semibold text-base text-white" };
const _hoisted_7 = { class: "text-sm text-gray-500 group-hover:text-gray-100 mt-1" };
const _hoisted_8 = { class: "font-semibold text-base text-white" };
const _hoisted_9 = { class: "text-sm text-gray-500 group-hover:text-gray-100 mt-1" };
const _hoisted_10 = {
  href: "https://dmm.injective.network",
  target: "_blank",
  class: "p-4 block group bg-gray-850 hover:bg-gray-700"
};
const _hoisted_11 = { class: "font-semibold text-base text-white flex items-center" };
const _hoisted_12 = { class: "text-sm text-gray-500 group-hover:text-gray-100 mt-1" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { DefaultMarket, TradeClickOrigin } from "@/types";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import {
  getDefaultPerpetualMarketRouteParams,
  getDefaultSpotMarketRouteParams
} from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const walletStore = useWalletStore();
    const defaultPerpetualMarketRoute = getDefaultPerpetualMarketRouteParams();
    const defaultSpotMarketRoute = getDefaultSpotMarketRouteParams();
    const tradeDropdownShown = ref(false);
    const rewardsDropdownShown = ref(false);
    function handleSpotTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: DefaultMarket.Spot,
        marketType: MarketType.Spot,
        origin: TradeClickOrigin.TopMenu
      });
    }
    function handlePerpetualTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: DefaultMarket.Perpetual,
        marketType: MarketType.Perpetual,
        origin: TradeClickOrigin.TopMenu
      });
    }
    function handleTradeDropdownShownChange(value) {
      tradeDropdownShown.value = value;
      if (value) {
        rewardsDropdownShown.value = false;
      }
    }
    function handleRewardsDropdownShownChange(value) {
      rewardsDropdownShown.value = value;
      if (value) {
        tradeDropdownShown.value = false;
      }
    }
    return (_ctx, _cache) => {
      const _component_LayoutNavItem = __nuxt_component_0;
      const _component_LayoutNavItemDummy = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      const _component_LayoutNavHoverMenu = __nuxt_component_3;
      const _component_LayoutNavMobile = __nuxt_component_4;
      const _component_BaseIcon = __nuxt_component_5;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("nav", _hoisted_1, [
          _createVNode(_component_LayoutNavItem, {
            to: { name: "index" },
            class: "block lg:hidden"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("navigation.home")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LayoutNavItem, {
            to: { name: "markets" },
            class: "block",
            "data-cy": "header-markets-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("trade.markets")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_LayoutNavHoverMenu, {
            shown: _unref(tradeDropdownShown),
            "onDropdown:toggle": handleTradeDropdownShownChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutNavItemDummy, {
                id: "trade-dropdown",
                class: "hidden lg:block"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("navigation.trade")), 1)
                ]),
                _: 1
              })
            ]),
            content: _withCtx(() => [
              _createVNode(_component_NuxtLink, {
                to: _unref(defaultSpotMarketRoute),
                class: "p-4 block rounded-t group hover:bg-gray-700 relative z-50 bg-gray-850",
                "data-cy": "header-trade-link",
                onClick: handleSpotTradeClickedTrack
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("navigation.spot")), 1),
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("navigation.spotDescription")), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_NuxtLink, {
                to: _unref(defaultPerpetualMarketRoute),
                class: "p-4 block group hover:bg-gray-700 relative z-50 bg-gray-850",
                "data-cy": "header-trade-link",
                onClick: handlePerpetualTradeClickedTrack
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("navigation.perpetual")), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("navigation.perpetualDescription")), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_NuxtLink, {
                to: { name: "convert" },
                class: "p-4 block rounded-b group hover:bg-gray-700 relative z-50 bg-gray-850",
                "data-cy": "header-convert-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("navigation.convert")), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("navigation.convertDescription")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["shown"]),
          _createVNode(_component_LayoutNavMobile),
          _createVNode(_component_LayoutNavHoverMenu, {
            shown: _unref(rewardsDropdownShown),
            "onDropdown:toggle": handleRewardsDropdownShownChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutNavItemDummy, {
                id: "rewards-dropdown",
                class: "hidden lg:block"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("navigation.rewards")), 1)
                ]),
                _: 1
              })
            ]),
            content: _withCtx(() => [
              _createVNode(_component_NuxtLink, {
                to: { name: "trade-and-earn" },
                class: "p-4 block rounded-t group relative z-50 bg-gray-850 hover:bg-gray-700"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("navigation.tradeAndEarn")), 1),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("navigation.tradeAndEarnDescription")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("a", _hoisted_10, [
                _createElementVNode("p", _hoisted_11, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("navigation.dmmProgram")), 1),
                  _createVNode(_component_BaseIcon, {
                    name: "external-link",
                    arrow: "",
                    class: "w-auto h-3 ml-2"
                  })
                ]),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("navigation.dmmProgramDescription")), 1)
              ])
            ]),
            _: 1
          }, 8, ["shown"]),
          _createVNode(_component_LayoutNavItem, {
            to: { name: "NFT" },
            class: "block",
            "data-cy": "header-markets-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("NFT")), 1)
            ]),
            _: 1
          }),
          _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_LayoutNavItem, {
            key: 0,
            class: "block lg:hidden",
            "data-cy": "header-account-link",
            to: { name: "account", query: { view: "balances" } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("navigation.account")), 1)
            ]),
            _: 1
          })) : _createCommentVNode("", true),
          _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_LayoutNavItem, {
            key: 1,
            class: "block lg:hidden",
            "data-cy": "nav-activity-link",
            to: { name: "activity" }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("navigation.activity")), 1)
            ]),
            _: 1
          })) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});
