import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between text-xs" };
const _hoisted_2 = { class: "font-semibold" };
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { UI_MINIMAL_AMOUNT } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "TierLevel",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const accountInfo = computed(() => {
      if (!exchangeStore.feeDiscountAccountInfo) {
        return;
      }
      return exchangeStore.feeDiscountAccountInfo.accountInfo;
    });
    const tierLevel = computed(() => {
      if (!exchangeStore.feeDiscountAccountInfo) {
        return 0;
      }
      return new BigNumberInBase(
        exchangeStore.feeDiscountAccountInfo.tierLevel || 0
      ).toNumber();
    });
    const takerFeeDiscount = computed(() => {
      if (!accountInfo.value) {
        return "";
      }
      const takerFeeDiscount2 = new BigNumberInWei(
        accountInfo.value.takerDiscountRate
      ).toBase();
      if (takerFeeDiscount2.lte(UI_MINIMAL_AMOUNT)) {
        return "0.0";
      }
      return takerFeeDiscount2.toFormat();
    });
    const makerFeeDiscount = computed(() => {
      if (!accountInfo.value) {
        return "";
      }
      const makerFeeDiscount2 = new BigNumberInWei(
        accountInfo.value.makerDiscountRate
      ).toBase();
      if (makerFeeDiscount2.lte(UI_MINIMAL_AMOUNT)) {
        return "0.0";
      }
      return makerFeeDiscount2.toFormat();
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("fee_discounts.tier")), 1),
        _createVNode(_component_NuxtLink, {
          to: { name: "fee-discounts" },
          class: "text-green-500 hover:opacity-80 flex items-center gap-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_unref(tierLevel) > 0 ? _ctx.$t("navigation.makerTakerFee", {
              maker: _unref(makerFeeDiscount),
              taker: _unref(takerFeeDiscount)
            }) : _ctx.$t("navigation.noTierLevel")), 1),
            _createVNode(_component_BaseIcon, {
              name: "external-link-arrow",
              xs: ""
            })
          ]),
          _: 1
        })
      ]);
    };
  }
});
