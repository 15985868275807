import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Input/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Select/Field/Item.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Dropdown.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-white text-sm"
};
const _hoisted_2 = {
  key: 1,
  class: "text-gray-500 text-sm"
};
const _hoisted_3 = { class: "flex items-center gap-2" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    clearable: Boolean,
    searchable: Boolean,
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    selectedClass: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const uuid = Math.random();
    const search = ref("");
    const value = computed({
      get: () => props.modelValue,
      set: (value2) => {
        if (value2) {
          emit("update:modelValue", value2);
        }
      }
    });
    const selectedItem = computed(
      () => props.options.find(({ value: value2 }) => value2 === props.modelValue)
    );
    const filteredList = computed(() => {
      return props.options.filter((option) => {
        const searchTrimmed = search.value.toLocaleLowerCase().trim();
        if (!searchTrimmed) {
          return true;
        }
        return option.display.toLowerCase().startsWith(searchTrimmed);
      });
    });
    function handleClear() {
      emit("update:modelValue", "");
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppInput = __nuxt_component_1;
      const _component_AppSelectFieldItem = __nuxt_component_2;
      const _component_BaseDropdown = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_BaseDropdown, {
        class: "w-full",
        delay: 300,
        "auto-size": "true",
        "auto-boundary-max-size": "",
        "popper-class": "dropdown"
      }, {
        default: _withCtx(({ shown }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center justify-between px-4 h-10 box-border bg-gray-1000 border rounded-lg cursor-pointer text-sm", [
              __props.selectedClass,
              shown ? "border-blue-500" : "border-transparent"
            ]])
          }, [
            _renderSlot(_ctx.$slots, "selected-option", { option: _unref(selectedItem) }, () => [
              _createElementVNode("div", null, [
                _unref(selectedItem) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(selectedItem).display), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.placeholder), 1))
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              __props.clearable && _unref(selectedItem) ? (_openBlock(), _createBlock(_component_BaseIcon, {
                key: 0,
                name: "close",
                class: "min-w-4 w-4 h-4 text-gray-500 hover:text-white",
                onClick: _withModifiers(handleClear, ["stop"])
              }, null, 8, ["onClick"])) : _createCommentVNode("", true),
              _createVNode(_component_BaseIcon, {
                name: "caret-down-thick",
                class: _normalizeClass(["ease-in-out duration-300 min-w-3 w-3 h-3", {
                  "text-gray-500": !shown,
                  "text-blue-500 rotate-180": shown
                }])
              }, null, 8, ["class"])
            ])
          ], 2)
        ]),
        content: _withCtx(({ close }) => [
          _renderSlot(_ctx.$slots, "list", {}, () => [
            _createElementVNode("div", {
              class: "p-2 py-4 max-h-xs space-y-3",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {
              }, ["stop"]))
            }, [
              __props.searchable ? (_openBlock(), _createBlock(_component_AppInput, {
                key: 0,
                modelValue: _unref(search),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(search) ? search.value = $event : null),
                class: "text-white",
                sm: "",
                "bg-transparent": "",
                placeholder: _ctx.$t("common.search")
              }, null, 8, ["modelValue", "placeholder"])) : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredList), (item) => {
                  return _openBlock(), _createBlock(_component_AppSelectFieldItem, {
                    key: `${_unref(uuid)}-${item}`,
                    modelValue: _unref(value),
                    "onUpdate:modelValue": [
                      _cache[1] || (_cache[1] = ($event) => _isRef(value) ? value.value = $event : null),
                      close
                    ],
                    value: item.value
                  }, {
                    default: _withCtx(({ active }) => [
                      _renderSlot(_ctx.$slots, "option", {
                        option: item,
                        active
                      }, () => [
                        _createElementVNode("div", {
                          class: _normalizeClass({
                            "text-white": !active,
                            "text-blue-500 group-hover:text-white": active
                          })
                        }, [
                          _createElementVNode("span", null, _toDisplayString(item.display), 1)
                        ], 2)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "value", "onUpdate:modelValue"]);
                }), 128))
              ])
            ])
          ])
        ]),
        _: 3
      });
    };
  }
});
