"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Item",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const isActive = computed(() => props.modelValue === props.value);
    function handleClick() {
      emit("update:modelValue", props.value);
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "p-2 hover:bg-blue-500 rounded text-white text-sm font-semibold cursor-pointer group",
        onClick: handleClick
      }, [
        _renderSlot(_ctx.$slots, "default", { active: _unref(isActive) })
      ]);
    };
  }
});
