"use strict";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "block hover:bg-gray-800 cursor-pointer px-6" };
const _hoisted_2 = { class: "flex items-center py-4" };
const _hoisted_3 = { class: "min-w-0 flex-1 flex items-center" };
const _hoisted_4 = { class: "flex-shrink-0 mr-2 text-gray-200" };
const _hoisted_5 = { class: "min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4 text-left" };
const _hoisted_6 = { class: "text-xl font-semibold text-white flex items-center gap-1" };
const _hoisted_7 = { class: "flex items-center text-base text-gray-400 gap-2 flex-wrap" };
export function render(_ctx, _cache) {
  const _component_BaseIcon = _resolveComponent("BaseIcon");
  return _openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "logo")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_6, [
              _renderSlot(_ctx.$slots, "title")
            ]),
            _createElementVNode("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "description"),
              _renderSlot(_ctx.$slots, "addon")
            ])
          ])
        ])
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "icon", {}, () => [
          _createVNode(_component_BaseIcon, {
            name: "caret-down",
            class: "transform -rotate-90 h-5 w-5 text-white"
          })
        ])
      ])
    ])
  ]);
}
