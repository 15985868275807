import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InsufficientGasInner.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "InsufficientInjForGas",
  setup(__props) {
    const modalStore = useModalStore();
    function closeModal() {
      modalStore.closeModal(Modal.InsufficientInjForGas);
    }
    return (_ctx, _cache) => {
      const _component_CommonInsufficientGasInner = __nuxt_component_0;
      const _component_AppModal = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(modalStore).modals[_unref(Modal).InsufficientInjForGas],
        sm: "",
        "onModal:closed": closeModal
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("insufficientGas.insufficientGas")), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_CommonInsufficientGasInner, {
            "hide-title": true,
            onClose: closeModal
          })
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
