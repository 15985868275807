import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "p-4 bg-gray-900 mt-6 text-xs text-gray-300 rounded-lg" };
const _hoisted_3 = { class: "font-bold text-gray-200" };
const _hoisted_4 = { class: "mt-2" };
const _hoisted_5 = { class: "mt-2" };
const _hoisted_6 = { class: "mt-2" };
const _hoisted_7 = { class: "mt-2" };
const _hoisted_8 = { class: "mt-2" };
const _hoisted_9 = { class: "mt-6 flex items-center justify-center gap-3" };
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Terms",
  setup(__props) {
    const modalStore = useModalStore();
    const isModalOpen = computed(() => modalStore.modals[Modal.Terms]);
    function handleConfirm() {
      closeModal();
      modalStore.openModal({ type: Modal.Connect });
    }
    function handleCancel() {
      closeModal();
    }
    function closeModal() {
      modalStore.closeModal(Modal.Terms);
    }
    return (_ctx, _cache) => {
      const _component_NuxtLink = __nuxt_component_0;
      const _component_i18n_t = _resolveComponent("i18n-t");
      const _component_AppButton = __nuxt_component_1;
      const _component_AppModal = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        "onModal:closed": closeModal
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("Acknowledge Terms")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_i18n_t, {
              keypath: "terms.disclaimer_note",
              tag: "p",
              class: "text-sm"
            }, {
              terms: _withCtx(() => [
                _createVNode(_component_NuxtLink, {
                  target: "_blank",
                  class: "text-blue-500 hover:text-opacity-80",
                  to: "https://injectivelabs.org/terms-and-conditions"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("terms.termsAndCondition")), 1)
                  ]),
                  _: 1
                })
              ]),
              policy: _withCtx(() => [
                _createVNode(_component_NuxtLink, {
                  target: "_blank",
                  class: "text-blue-500 hover:text-opacity-80",
                  to: "https://injectivelabs.org/privacy-policy"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("terms.privacyPolicy")), 1)
                  ]),
                  _: 1
                })
              ]),
              disclaimer: _withCtx(() => [
                _createVNode(_component_NuxtLink, {
                  target: "_blank",
                  class: "text-blue-500 hover:text-opacity-80",
                  to: "https://injectiveprotocol.com/disclaimer"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("terms.disclaimer")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("ul", _hoisted_2, [
              _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.$t("terms.title")), 1),
              _createElementVNode("li", _hoisted_4, _toDisplayString(_ctx.$t("terms.acknowledge_1")), 1),
              _createElementVNode("li", _hoisted_5, _toDisplayString(_ctx.$t("terms.acknowledge_2")), 1),
              _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.$t("terms.acknowledge_3")), 1),
              _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t("terms.acknowledge_4")), 1),
              _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.$t("terms.acknowledge_5")), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_AppButton, {
                class: "bg-blue-500 text-blue-900 font-semibold",
                onClick: handleConfirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.confirm")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppButton, {
                class: "text-red-500 bg-red-500 bg-opacity-10 font-semibold hover:text-white",
                onClick: handleCancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
