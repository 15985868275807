import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Field/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "text-sm font-semibold mb-2" };
const _hoisted_2 = {
  key: 0,
  class: "text-gray-400 text-xs my-2 flex items-center gap-2"
};
const _hoisted_3 = /* @__PURE__ */ _createElementVNode("div", { class: "border-b border-gray-600 mt-4 mb-4" }, null, -1);
const _hoisted_4 = { key: 2 };
const _hoisted_5 = { class: "text-sm font-semibold mb-2" };
const _hoisted_6 = {
  key: 0,
  class: "text-red-500 text-sm capitalize-phrase mt-1"
};
const _hoisted_7 = { class: "text-xs text-gray-400 mt-4" };
import { Status, StatusType } from "@injectivelabs/utils";
import { LedgerDerivationPathType, Wallet } from "@injectivelabs/wallet-ts";
import { WalletConnectStatus } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Ledger",
  setup(__props) {
    const walletStore = useWalletStore();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const { handleSubmit } = useForm();
    const options = [
      {
        display: t("connect.ledgerLive"),
        value: LedgerDerivationPathType.LedgerLive
      },
      {
        display: t("connect.ledgerLegacy"),
        value: LedgerDerivationPathType.LedgerMew
      }
    ];
    const path = ref(void 0);
    const status = reactive(new Status(StatusType.Idle));
    const fetchStatus = reactive(new Status(StatusType.Idle));
    const { value: address, errors: addressErrors } = useStringField({
      name: "address"
    });
    onMounted(() => {
      walletStore.$patch({
        addresses: []
      });
    });
    function fetchAddresses() {
      fetchStatus.setLoading();
      const wallet = path.value === LedgerDerivationPathType.LedgerLive ? Wallet.Ledger : Wallet.LedgerLegacy;
      walletStore.getHWAddresses(wallet).catch($onError).finally(() => {
        fetchStatus.setIdle();
      });
    }
    const connect = handleSubmit(() => {
      status.setLoading();
      walletStore.connectLedger(address.value).catch((e) => {
        walletStore.setWalletConnectStatus(WalletConnectStatus.disconnected);
        $onError(e);
      }).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_AppSelectField = __nuxt_component_0;
      const _component_AppSpinner = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      const _component_AppButton = __nuxt_component_3;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("connect.derivationPath")), 1),
        _createVNode(_component_AppSelectField, {
          modelValue: _unref(path),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(path) ? path.value = $event : null),
          options: _unref(options),
          placeholder: _ctx.$t("connect.selectDerivationPath")
        }, null, 8, ["modelValue", "options", "placeholder"]),
        _unref(fetchStatus).isLoading() ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createVNode(_component_AppSpinner, { sm: "" }),
          _createTextVNode(),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("connect.getAddressNote")), 1)
        ])) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "flex items-center gap-1 text-blue-500 hover:text-opacity-80 cursor-pointer text-sm mt-2",
          onClick: fetchAddresses
        }, [
          _createElementVNode("span", null, _toDisplayString(_unref(walletStore).addresses.length === 0 ? _ctx.$t("connect.getAddresses") : _ctx.$t("connect.getMoreAddresses")), 1),
          _createVNode(_component_BaseIcon, {
            name: "arrow",
            class: "transform rotate-180 w-4 h-4"
          })
        ])),
        _hoisted_3,
        _unref(walletStore).addresses.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("connect.address")), 1),
          _createVNode(_component_AppSelectField, {
            modelValue: _unref(address),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(address) ? address.value = $event : null),
            searchable: "",
            options: _unref(walletStore).addresses.map((address2) => ({
              display: address2,
              value: address2
            })),
            placeholder: _ctx.$t("connect.selectAddressToConnect")
          }, null, 8, ["modelValue", "options", "placeholder"]),
          _unref(addressErrors).length > 0 ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_unref(addressErrors)[0]), 1)) : _createCommentVNode("", true),
          _createVNode(_component_AppButton, {
            class: "w-full mt-4 text-blue-900 bg-blue-500 font-semibold",
            disabled: _unref(addressErrors).length > 0,
            status: _unref(status),
            lg: "",
            onClick: _unref(connect)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("connect.connect")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "status", "onClick"])
        ])) : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("connect.connectUsingLedgerNote")), 1)
      ]);
    };
  }
});
