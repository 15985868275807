import { default as __nuxt_component_0 } from "/opt/build/repo/components/Asset/LogoMini.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Asset/LogoText1.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_3 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "w-full py-16 bg-gray-1000 border-t border-t-gray-900" };
const _hoisted_2 = { class: "w-full mx-auto lg:w-4/5 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 3md:grid-cols-10 lg:grid-cols-12 gap-8 px-8 lg:px-0" };
const _hoisted_3 = { class: "sm:col-span-1 xs:col-span-2 3md:col-span-3 lg:col-span-5" };
const _hoisted_4 = { class: "text-gray-500 text-xs" };
const _hoisted_5 = { class: "hidden text-sm opacity-75 lg:block mt-6 italic max-w-[450px]" };
const _hoisted_6 = { class: "3md:col-span-2" };
const _hoisted_7 = { class: "font-bold text-lg mb-4" };
const _hoisted_8 = ["href"];
const _hoisted_9 = { class: "3md:col-span-2" };
const _hoisted_10 = { class: "font-bold text-lg mb-4" };
const _hoisted_11 = ["href"];
const _hoisted_12 = { class: "md:col-span-3" };
const _hoisted_13 = { class: "font-bold text-lg mb-4 md:mb-6" };
const _hoisted_14 = { class: "flex items-center" };
const _hoisted_15 = {
  class: "w-6 h-6 text-gray-500 hover:text-blue-500 mr-4",
  href: "https://discord.com/invite/injective",
  target: "_blank"
};
const _hoisted_16 = {
  class: "w-6 h-6 text-gray-500 hover:text-blue-500 mr-4",
  href: "https://twitter.com/DexParallel",
  target: "_blank"
};
const _hoisted_17 = {
  class: "w-6 h-6 text-gray-500 hover:text-blue-500 mr-4",
  href: "https://t.me/paralleldex",
  target: "_blank"
};
const _hoisted_18 = { class: "xs:col-span-2 sm:col-span-3 md:col-span-5 lg:hidden text-sm" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const { t } = useLang();
    const aboutUsList = [
      {
        text: t("footer.feeDiscounts"),
        link: "fee-discounts"
      },
      {
        text: t("footer.apiDocumentation"),
        link: "https://api.injective.exchange/"
      },
      {
        text: t("footer.privacyPolicy"),
        link: "https://injectivelabs.org/privacy-policy"
      },
      {
        text: t("footer.termsAndConditions"),
        link: "https://injectivelabs.org/terms-and-conditions"
      }
    ];
    const learnList = [
      {
        text: t("footer.faq"),
        link: "https://paralleldex.freshdesk.com/"
      }
    ];
    return (_ctx, _cache) => {
      const _component_AssetLogoMini = __nuxt_component_0;
      const _component_AssetLogoText1 = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      const _component_BaseIcon = __nuxt_component_3;
      return _openBlock(), _createElementBlock("footer", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer md:mb-6",
              onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push({ name: "index" }))
            }, [
              _createVNode(_component_AssetLogoMini, {
                class: "h-10 w-10 mr-2",
                alt: "Parallel"
              }),
              _createVNode(_component_AssetLogoText1, { class: "h-6 md:h-8" })
            ]),
            _createElementVNode("span", _hoisted_4, " \xA9 " + _toDisplayString(new Date().getFullYear()) + " Injective Labs Inc. ", 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("footer.helixProvides")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("footer.resources")), 1),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(aboutUsList, (item, index) => {
              return _createElementVNode("div", {
                key: `about-us-list-${index}`,
                class: "mt-4 text-sm"
              }, [
                item.link.startsWith("http") ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: item.link,
                  class: "opacity-75 cursor-pointer hover:text-blue-500",
                  target: "_blank"
                }, _toDisplayString(item.text), 9, _hoisted_8)) : item.link ? (_openBlock(), _createBlock(_component_NuxtLink, {
                  key: 1,
                  to: { name: item.link },
                  class: "opacity-75 cursor-pointer hover:text-blue-500"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.text), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])) : _createCommentVNode("", true)
              ]);
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("footer.support")), 1),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(learnList, (item, index) => {
              return _createElementVNode("div", {
                key: `about-us-list-${index}`,
                class: "mt-4 text-sm"
              }, [
                item.link.startsWith("http") ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: item.link,
                  class: "opacity-75 cursor-pointer hover:text-blue-500",
                  target: "_blank"
                }, _toDisplayString(item.text), 9, _hoisted_11)) : item.link ? (_openBlock(), _createBlock(_component_NuxtLink, {
                  key: 1,
                  to: { name: item.link },
                  class: "opacity-75 cursor-pointer hover:text-blue-500"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.text), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])) : _createCommentVNode("", true)
              ]);
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t("footer.community")), 1),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("a", _hoisted_15, [
                _createVNode(_component_BaseIcon, { name: "discord-circle" })
              ]),
              _createElementVNode("a", _hoisted_16, [
                _createVNode(_component_BaseIcon, { name: "twitter-circle" })
              ]),
              _createElementVNode("a", _hoisted_17, [
                _createVNode(_component_BaseIcon, { name: "telegram-circle" })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("footer.helixProvides")), 1)
        ])
      ]);
    };
  }
});
