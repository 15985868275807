import { default as __nuxt_component_0 } from "/opt/build/repo/components/Layout/Nav/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "lg:hidden" };
const _hoisted_2 = {
  key: 0,
  class: "relative flex-1 flex flex-col w-full pt-5 pb-4 bg-gray-900 max-w-xs"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = { class: "flex-1 flex flex-col overflow-y-auto" };
export default /* @__PURE__ */ _defineComponent({
  __name: "SidebarMobile",
  props: {
    isOpenSidebar: null
  },
  emits: ["sidebar:closed"],
  setup(__props, { emit }) {
    const { width } = useWindowSize();
    const zIndex = ref(0);
    function handleSidebarClose() {
      emit("sidebar:closed");
    }
    watchDebounced(
      width,
      (newWidth, oldWidth) => {
        if (oldWidth && newWidth >= 640) {
          handleSidebarClose();
        }
      },
      { debounce: 200, immediate: true }
    );
    return (_ctx, _cache) => {
      const _component_LayoutNav = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "fixed inset-0 flex top-[48px]",
          style: _normalizeStyle({
            zIndex: _unref(zIndex)
          }),
          onClick: handleSidebarClose
        }, [
          _createVNode(_Transition, {
            "enter-from-class": "-translate-x-full",
            "enter-active-class": "transition ease-in-out duration-300 transform",
            "enter-to-class": "translate-x-0",
            "leave-active-class": "transition ease-in-out duration-300 transform",
            "leave-from-class": "translate-x-0",
            "leave-to-class": "-translate-x-full",
            onBeforeEnter: _cache[0] || (_cache[0] = ($event) => zIndex.value = 40),
            onAfterLeave: _cache[1] || (_cache[1] = ($event) => zIndex.value = 0)
          }, {
            default: _withCtx(() => [
              __props.isOpenSidebar ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "flex flex-col h-0 flex-1",
                  onClick: _withModifiers(handleSidebarClose, ["stop"])
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_LayoutNav)
                  ])
                ], 8, _hoisted_3)
              ])) : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ], 4)
      ]);
    };
  }
});
