import { default as __nuxt_component_0 } from "/opt/build/repo/components/Layout/SidebarMobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Layout/Topbar.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/page";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Layout/Footer/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Modals/InsufficientInjForGas.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Modals/NinjaPassWinner.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/App/Confetti.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/client-only";
import { default as __nuxt_component_8 } from "/opt/build/repo/components/App/HocLoading.vue";
import { default as __nuxt_component_9 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_10 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Notification.vue";
import { default as __nuxt_component_11 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Notifications.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Transition as _Transition, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "pro",
  class: "flex min-h-screen max-h-screen bg-gray-1000 text-gray-100 relative overflow-x-hidden"
};
const _hoisted_2 = { class: "min-h-screen w-full" };
const _hoisted_3 = { class: "w-full" };
const _hoisted_4 = { class: "bg-gray-1000" };
const _hoisted_5 = /* @__PURE__ */ _createElementVNode("div", { id: "modals" }, null, -1);
import { Status, StatusType } from "@injectivelabs/utils";
import { ROUTES } from "@/app/utils/constants";
import { BusEvents } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "default",
  setup(__props) {
    const route = useRoute();
    const appStore = useAppStore();
    const bankStore = useBankStore();
    const spotStore = useSpotStore();
    const tokenStore = useTokenStore();
    const walletStore = useWalletStore();
    const exchangeStore = useExchangeStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    const isOpenSidebar = ref(false);
    const container = computed(() => document.getElementById("pro"));
    const showFooter = computed(
      () => ROUTES.footerEnabledRoutes.includes(route.name)
    );
    onMounted(() => {
      Promise.all([walletStore.init(), tokenStore.fetchSupplyTokenMeta()]).catch($onError).finally(() => {
        status.setIdle();
      });
      Promise.all([
        appStore.init(),
        spotStore.init(),
        derivativeStore.init(),
        exchangeStore.initFeeDiscounts(),
        tokenStore.fetchSupplyTokenMeta()
      ]);
      useEventBus(BusEvents.NavLinkClicked).on(onCloseSideBar);
    });
    onWalletConnected(() => {
      Promise.all([bankStore.fetchAccountPortfolio()]).catch($onError);
    });
    function onOpenSideBar() {
      var _a;
      isOpenSidebar.value = true;
      (_a = container.value) == null ? void 0 : _a.classList.add("overflow-y-hidden");
    }
    function onCloseSideBar() {
      var _a;
      if (isOpenSidebar.value) {
        isOpenSidebar.value = false;
        (_a = container.value) == null ? void 0 : _a.classList.remove("overflow-y-hidden");
      }
    }
    return (_ctx, _cache) => {
      const _component_LayoutSidebarMobile = __nuxt_component_0;
      const _component_LayoutTopbar = __nuxt_component_1;
      const _component_NuxtPage = __nuxt_component_2;
      const _component_LayoutFooter = __nuxt_component_3;
      const _component_ModalsInsufficientInjForGas = __nuxt_component_4;
      const _component_ModalsNinjaPassWinner = __nuxt_component_5;
      const _component_ModalsUserFeedback = _resolveComponent("ModalsUserFeedback");
      const _component_AppConfetti = __nuxt_component_6;
      const _component_client_only = __nuxt_component_7;
      const _component_AppHocLoading = __nuxt_component_8;
      const _component_BaseIcon = __nuxt_component_9;
      const _component_BaseNotification = __nuxt_component_10;
      const _component_BaseNotifications = __nuxt_component_11;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_Transition, {
          name: "page",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppHocLoading, {
                status: _unref(status),
                class: "h-full"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_LayoutSidebarMobile, _mergeProps({
                      isOpenSidebar: _unref(isOpenSidebar)
                    }, { "onSidebar:closed": onCloseSideBar }), null, 16),
                    _createVNode(_component_client_only, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_LayoutTopbar, {
                            "is-sidebar-open": _unref(isOpenSidebar),
                            "onSidebar:opened": onOpenSideBar,
                            "onSidebar:closed": onCloseSideBar
                          }, null, 8, ["is-sidebar-open"]),
                          _createElementVNode("main", {
                            class: _normalizeClass(["flex flex-wrap relative min-h-screen-excluding-header", {
                              "flex-col": _unref(showFooter),
                              "pt-12": _unref(isOpenSidebar)
                            }])
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["w-screen", [
                                { "max-h-screen-excluding-header": !_unref(showFooter) },
                                _unref(showFooter) ? "flex-auto" : "flex-1"
                              ]])
                            }, [
                              _createVNode(_component_NuxtPage)
                            ], 2),
                            _unref(showFooter) ? (_openBlock(), _createBlock(_component_LayoutFooter, { key: 0 })) : _createCommentVNode("", true)
                          ], 2),
                          _createVNode(_component_ModalsInsufficientInjForGas),
                          _createVNode(_component_ModalsNinjaPassWinner),
                          _createVNode(_component_ModalsUserFeedback),
                          _createVNode(_component_AppConfetti),
                          _hoisted_5
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }, 8, ["status"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_BaseNotifications, { class: "z-[1110] fixed inset-0 flex flex-col gap-2 justify-end items-end p-6 pointer-events-none" }, {
          notification: _withCtx(({ notification }) => [
            _createVNode(_component_BaseNotification, {
              notification,
              class: "pointer-events-auto bg-gray-800"
            }, {
              close: _withCtx(({ close }) => [
                _createVNode(_component_BaseIcon, {
                  name: "close-bold",
                  class: "min-w-4 hover:text-blue-500 text-white w-4 h-4",
                  onClick: close
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["notification"])
          ]),
          _: 1
        })
      ]);
    };
  }
});
