import { default as __nuxt_component_0 } from "/opt/build/repo/components/Layout/Wallet/Details/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Layout/Wallet/Ledger.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Layout/Wallet/Trezor.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Metamask.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Keplr.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Cosmostation.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Leap.vue";
import { default as __nuxt_component_8 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Torus.vue";
import { default as __nuxt_component_9 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Ledger.vue";
import { default as __nuxt_component_10 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wallet/Trezor.vue";
import { default as __nuxt_component_11 } from "/opt/build/repo/components/App/Modal.vue";
import { default as __nuxt_component_12 } from "/opt/build/repo/components/Modals/Terms.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
const _hoisted_4 = {
  key: 2,
  class: "divide-y divide-gray-800 border-gray-700 rounded-lg max-h-[65vh]"
};
import { Status, StatusType } from "@injectivelabs/utils";
import { GEO_IP_RESTRICTIONS_ENABLED } from "@/app/utils/constants";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import {
  Modal,
  BusEvents,
  AmplitudeEvent,
  WalletModalType,
  WalletConnectStatus
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const status = reactive(new Status(StatusType.Loading));
    const walletModalType = ref(WalletModalType.All);
    const isModalOpen = computed(
      () => modalStore.modals[Modal.Connect] && !walletStore.isUserWalletConnected
    );
    const showLoading = computed(
      () => walletStore.walletConnectStatus === WalletConnectStatus.connecting
    );
    onMounted(() => {
      useEventBus(BusEvents.ShowLedgerConnect).on(handleLedgerConnect);
      Promise.all([walletStore.isMetamaskInstalled()]).finally(
        () => status.setIdle()
      );
    });
    function handleLedgerConnect() {
      walletModalType.value = WalletModalType.Ledger;
      modalStore.openModal({ type: Modal.Connect });
    }
    function handleWalletConnect() {
      amplitudeTracker.trackEvent(AmplitudeEvent.ConnectClicked);
      if (GEO_IP_RESTRICTIONS_ENABLED) {
        modalStore.openModal({ type: Modal.Terms });
      } else {
        modalStore.openModal({ type: Modal.Connect });
      }
    }
    function handleModalClose() {
      modalStore.closeModal(Modal.Connect);
    }
    function updateWalletModalType(type) {
      walletModalType.value = type;
    }
    watch(
      () => walletStore.walletConnectStatus,
      (newWalletConnectStatus) => {
        if (newWalletConnectStatus === WalletConnectStatus.connected) {
          modalStore.closeModal(Modal.Connect);
          modalStore.openPersistedModalIfExist();
        }
      }
    );
    watch(isModalOpen, (newShowModalState) => {
      if (!newShowModalState) {
        handleModalClose();
        walletModalType.value = WalletModalType.All;
      }
    });
    return (_ctx, _cache) => {
      const _component_LayoutWalletDetails = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_LayoutWalletLedger = __nuxt_component_2;
      const _component_LayoutWalletTrezor = __nuxt_component_3;
      const _component_LayoutWalletConnectWalletMetamask = __nuxt_component_4;
      const _component_LayoutWalletConnectWalletKeplr = __nuxt_component_5;
      const _component_LayoutWalletConnectWalletCosmostation = __nuxt_component_6;
      const _component_LayoutWalletConnectWalletLeap = __nuxt_component_7;
      const _component_LayoutWalletConnectWalletTorus = __nuxt_component_8;
      const _component_LayoutWalletConnectWalletLedger = __nuxt_component_9;
      const _component_LayoutWalletConnectWalletTrezor = __nuxt_component_10;
      const _component_AppModal = __nuxt_component_11;
      const _component_ModalsTerms = __nuxt_component_12;
      return _openBlock(), _createElementBlock(_Fragment, null, [
        _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_LayoutWalletDetails, { key: 0 })) : (_openBlock(), _createBlock(_component_AppButton, {
          key: 1,
          class: "bg-blue-500 text-blue-900 font-semibold whitespace-nowrap",
          onClick: handleWalletConnect
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("connect.connectWallet")), 1)
          ]),
          _: 1
        })),
        _createVNode(_component_AppModal, {
          show: _unref(isModalOpen),
          "show-loading": _unref(showLoading),
          ignore: [".v-popper__popper"],
          md: "",
          "onModal:closed": handleModalClose
        }, {
          title: _withCtx(() => [
            _unref(walletModalType) === _unref(WalletModalType).Trezor ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$t("connect.connectUsingTrezor")), 1)) : _unref(walletModalType) === _unref(WalletModalType).Ledger ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t("connect.connectUsingLedger")), 1)) : (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t("connect.connectToWallet")), 1))
          ]),
          default: _withCtx(() => [
            _unref(walletModalType) === _unref(WalletModalType).Ledger ? (_openBlock(), _createBlock(_component_LayoutWalletLedger, { key: 0 })) : _unref(walletModalType) === _unref(WalletModalType).Trezor ? (_openBlock(), _createBlock(_component_LayoutWalletTrezor, { key: 1 })) : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              _createVNode(_component_LayoutWalletConnectWalletMetamask),
              _createVNode(_component_LayoutWalletConnectWalletKeplr),
              false ? (_openBlock(), _createBlock(_component_LayoutWalletConnectWalletCosmostation, { key: 0 })) : _createCommentVNode("", true),
              _createVNode(_component_LayoutWalletConnectWalletLeap),
              _createVNode(_component_LayoutWalletConnectWalletTorus),
              _createVNode(_component_LayoutWalletConnectWalletLedger, { onClick: updateWalletModalType }),
              _createVNode(_component_LayoutWalletConnectWalletTrezor, { onClick: updateWalletModalType })
            ]))
          ]),
          _: 1
        }, 8, ["show", "show-loading", "ignore"]),
        _createVNode(_component_ModalsTerms)
      ], 64);
    };
  }
});
