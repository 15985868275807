import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Layout/Wallet/Details/ConnectedWallet.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/HoverMenu.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex items-center" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "hidden lg:block font-mono" };
const _hoisted_4 = { class: "flex flex-col gap-4 rounded-lg p-4 bg-gray-850" };
const _hoisted_5 = { class: "rounded-lg bg-gray-1000" };
const _hoisted_6 = { class: "flex flex-col py-3 px-4" };
const _hoisted_7 = { class: "flex justify-between pb-2" };
const _hoisted_8 = { class: "text-sm font-semibold text-gray-200" };
import { formatWalletAddress } from "@injectivelabs/utils";
import { ROUTES } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const walletStore = useWalletStore();
    const formattedInjectiveAddress = computed(
      () => formatWalletAddress(walletStore.injectiveAddress)
    );
    function handleDisconnect() {
      walletStore.logout();
      if (ROUTES.walletConnectedRequiredRouteNames.includes(route.name)) {
        router.push({ name: "index" });
      }
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_LayoutWalletDetailsConnectedWallet = __nuxt_component_1;
      const _component_BaseHoverMenu = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseHoverMenu, { "popper-class": "min-w-[310px] sm:min-w-[356px] bg-gray-850 shadow-dropdown rounded-lg" }, {
          default: _withCtx(({ toggle }) => [
            _createElementVNode("div", {
              class: "font-medium text-sm cursor-pointer flex items-center justify-center lg:justify-start w-10 h-10 lg:w-auto lg:px-6 rounded-lg",
              onClick: toggle
            }, [
              _createVNode(_component_BaseIcon, {
                name: "user",
                class: "w-4 h-4 lg:mr-2"
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(formattedInjectiveAddress)), 1)
            ], 8, _hoisted_2)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("navigation.myAccount")), 1),
                    _createElementVNode("span", {
                      class: "text-blue-500 hover:text-opacity-80 cursor-pointer text-xs font-medium",
                      onClick: handleDisconnect
                    }, _toDisplayString(_ctx.$t("navigation.disconnect")), 1)
                  ]),
                  _createVNode(_component_LayoutWalletDetailsConnectedWallet, {
                    wallet: _unref(walletStore).wallet
                  }, null, 8, ["wallet"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]);
    };
  }
});
