import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "block" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { BusEvents, DefaultMarket, TradeClickOrigin } from "@/types";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
export default /* @__PURE__ */ _defineComponent({
  __name: "Item",
  props: {
    dense: Boolean
  },
  setup(__props) {
    const props = __props;
    const attrs = useAttrs();
    const classes = computed(() => {
      if (props.dense) {
        return ["hover:text-blue-500"];
      }
      return ["px-6", "py-2", "hover:bg-gray-800", "hover:text-white"];
    });
    const spotMarket = computed(() => {
      const attributes = attrs;
      if (!attributes || !attributes.to || !attributes.to.params) {
        return "";
      }
      return attributes.to.params.spot;
    });
    const futuresMarket = computed(() => {
      const attributes = attrs;
      if (!attributes || !attributes.to || !attributes.to.params) {
        return "";
      }
      return attributes.to.params.futures;
    });
    const market = computed(
      () => spotMarket ? DefaultMarket.Spot : DefaultMarket.Perpetual
    );
    const marketType = computed(
      () => spotMarket ? MarketType.Spot : MarketType.Perpetual
    );
    function handleVisit() {
      if (spotMarket.value || futuresMarket.value) {
        handleTradeClickedTrack();
      }
      useEventBus(BusEvents.NavLinkClicked).emit();
    }
    function handleTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: market.value,
        marketType: marketType.value,
        origin: TradeClickOrigin.TopMenu
      });
    }
    return (_ctx, _cache) => {
      const _component_NuxtLink = __nuxt_component_0;
      return _openBlock(), _createBlock(_component_NuxtLink, _mergeProps(_ctx.$attrs, {
        class: ["text-gray-200 hover:bg-gray-800 hover:text-white text-sm font-semibold rounded-lg cursor-pointer mx-px h-10 flex items-center", _unref(classes)],
        exact: "",
        onClick: handleVisit
      }), {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      }, 16, ["class"]);
    };
  }
});
