import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("span", _mergeProps(_ctx.$attrs, { class: "text-gray-200 hover:bg-gray-800 hover:text-white text-sm font-semibold rounded-lg cursor-pointer mx-px h-10 flex lg:flex items-center px-6" }), [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 16))
}