"use strict";
import { defineComponent as _defineComponent } from "vue";
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Confetti",
  setup(__props) {
    const canvas = ref(null);
    onMounted(() => {
      useConfetti().init(canvas);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("canvas", {
        ref_key: "canvas",
        ref: canvas,
        class: "inset-0 fixed pointer-events-none w-screen h-screen z-50"
      }, null, 512);
    };
  }
});
