import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { "data-cy": "connect-wallet-popup-torus-button" };
const _hoisted_2 = { class: "grid grid-cols-4 gap-4 text-white" };
import { WalletConnectStatus } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Torus",
  setup(__props) {
    const walletStore = useWalletStore();
    const { success } = useNotifications();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    function connect() {
      walletStore.connectCosmostation().then(() => {
        success({ title: t("connect.successfullyConnected") });
      }).catch((e) => {
        walletStore.setWalletConnectStatus(WalletConnectStatus.disconnected);
        $onError(e);
      });
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_LayoutWalletConnectWrapper = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_LayoutWalletConnectWrapper, { onClick: connect }, {
        logo: _withCtx(() => [
          _createVNode(_component_BaseIcon, {
            name: "wallet/torus",
            class: "w-8 h-8"
          })
        ]),
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("connect.torus")), 1)
        ]),
        description: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("connect.connectUsingTorus")), 1)
        ]),
        addon: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseIcon, {
              name: "social/google",
              md: ""
            }),
            _createVNode(_component_BaseIcon, {
              name: "social/facebook",
              md: ""
            }),
            _createVNode(_component_BaseIcon, {
              name: "social/twitter",
              md: ""
            }),
            _createVNode(_component_BaseIcon, {
              name: "social/discord",
              md: ""
            })
          ])
        ]),
        _: 1
      });
    };
  }
});
