"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { "data-cy": "connect-wallet-popup-ledger-button" };
import { WalletModalType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Ledger",
  emits: ["click"],
  setup(__props, { emit }) {
    function showLedgerModal() {
      emit("click", WalletModalType.Ledger);
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      const _component_LayoutWalletConnectWrapper = _resolveComponent("LayoutWalletConnectWrapper");
      return _openBlock(), _createBlock(_component_LayoutWalletConnectWrapper, { onClick: showLedgerModal }, {
        logo: _withCtx(() => [
          _createVNode(_component_BaseIcon, {
            name: "wallet/ledger",
            class: "w-8 h-8"
          })
        ]),
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("connect.ledger")), 1)
        ]),
        description: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("connect.connectUsingHardware")), 1)
        ]),
        _: 1
      });
    };
  }
});
