import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Layout/Wallet/Details/TierLevel.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-col text-white" };
const _hoisted_2 = { class: "flex items-center justify-between" };
const _hoisted_3 = { class: "flex items-center gap-2" };
const _hoisted_4 = { class: "font-mono text-sm font-medium" };
const _hoisted_5 = { class: "flex items-center gap-2" };
const _hoisted_6 = {
  key: 0,
  class: "ml-8 mt-4 flex flex-col gap-y-2"
};
const _hoisted_7 = { class: "flex items-center justify-between text-xs" };
const _hoisted_8 = { class: "font-semibold" };
const _hoisted_9 = { class: "flex items-center gap-2" };
import { formatWalletAddress } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "ConnectedWallet",
  props: {
    wallet: {
      required: true,
      type: String
    }
  },
  setup(__props) {
    const walletStore = useWalletStore();
    const { copy } = useClipboard();
    const { t } = useLang();
    const { success } = useNotifications();
    const showDropdown = ref(false);
    const formattedInjectiveAddress = computed(
      () => formatWalletAddress(walletStore.injectiveAddress)
    );
    const formattedAddress = computed(
      () => formatWalletAddress(walletStore.address)
    );
    function handleToggleDropdown() {
      showDropdown.value = !showDropdown.value;
    }
    function copyAddress() {
      copy(walletStore.address);
      success({ title: t("connect.copiedAddress") });
    }
    function copyInjectiveAddress() {
      copy(walletStore.injectiveAddress);
      success({ title: t("connect.copiedAddress") });
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_LayoutWalletDetailsTierLevel = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseIcon, {
              name: `wallet/${__props.wallet}`,
              class: "h-6 w-6"
            }, null, 8, ["name"]),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(formattedInjectiveAddress)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseIcon, {
              name: "copy-filled",
              class: "hover:text-blue-500 h-4 w-4",
              onClick: _withModifiers(copyInjectiveAddress, ["stop"])
            }, null, 8, ["onClick"]),
            _createVNode(_component_BaseIcon, {
              name: "caret-down",
              class: _normalizeClass(["h-6 w-6 transition duration-500 hover:text-blue-500", { "-rotate-180": _unref(showDropdown) }]),
              onClick: handleToggleDropdown
            }, null, 8, ["class"])
          ])
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "ease-out duration-300",
          "enter-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "ease-in duration-200",
          "leave-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _unref(showDropdown) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("connect.walletAddress")), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString(_unref(formattedAddress)), 1),
                  _createVNode(_component_BaseIcon, {
                    name: "copy-filled",
                    class: "hover:text-blue-500 h-4 w-4",
                    onClick: copyAddress
                  })
                ])
              ]),
              _createVNode(_component_LayoutWalletDetailsTierLevel)
            ])) : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]);
    };
  }
});
