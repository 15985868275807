import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "normal-case" };
const _hoisted_2 = { class: "flex flex-col" };
const _hoisted_3 = { class: "text-sm mb-4" };
const _hoisted_4 = { class: "text-sm" };
const _hoisted_5 = { class: "grid grid-cols-1 gap-4 mt-6 sm:grid-cols-2" };
const _hoisted_6 = ["href"];
const _hoisted_7 = { class: "font-semibold text-blue-900" };
const _hoisted_8 = { class: "font-semibold text-blue-100" };
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "NinjaPassWinner",
  setup(__props) {
    const appStore = useAppStore();
    const walletStore = useWalletStore();
    const ninjaPassStore = useNinjaPassStore();
    const modalStore = useModalStore();
    const confetti = useConfetti();
    const isModalOpen = computed(() => modalStore.modals[Modal.NinjaPassWinner]);
    const ninjaPassCode = computed(() => {
      if (!ninjaPassStore.codes) {
        return;
      }
      return ninjaPassStore.codes[0];
    });
    const ninjaPassUrl = computed(() => {
      if (!ninjaPassCode.value) {
        return;
      }
      return `https://ninjapass.injective.com/?code=${ninjaPassCode.value.code}&address=${walletStore.injectiveAddress}`;
    });
    watch(
      () => ninjaPassCode,
      (code) => {
        if (code && !appStore.userState.ninjaPassWinnerModalViewed) {
          modalStore.openModal({ type: Modal.NinjaPassWinner });
          confetti.showConfetti();
        }
      }
    );
    onWalletConnected(() => {
      ninjaPassStore.fetchCodes();
    });
    function closeModal() {
      modalStore.closeModal(Modal.NinjaPassWinner);
      appStore.setUserState({
        ...appStore.userState,
        ninjaPassWinnerModalViewed: true
      });
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppModal = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "hide-close-button": "",
        "onModal:closed": closeModal
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t("ninjaPass.congratulations")), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("ninjaPass.title")), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("ninjaPass.description")), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", {
                href: _unref(ninjaPassUrl),
                target: "_blank",
                class: "bg-blue-500 py-2 h-10 rounded border flex items-center justify-center gap-2"
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("ninjaPass.verifyNow")), 1),
                _createVNode(_component_BaseIcon, {
                  name: "external-link",
                  class: "w-4 h-4 text-blue-900"
                })
              ], 8, _hoisted_6),
              _createElementVNode("button", {
                class: "bg-transparent py-2 h-10 rounded border border-blue-100",
                onClick: closeModal
              }, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("ninjaPass.later")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
