import { default as __nuxt_component_0 } from "/opt/build/repo/components/Layout/Nav/Item.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Accordion.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "block lg:hidden" };
const _hoisted_2 = { class: "text-sm font-semibold" };
const _hoisted_3 = { class: "font-normal tracking-wide" };
const _hoisted_4 = { class: "font-normal tracking-wide" };
const _hoisted_5 = { class: "font-normal tracking-wide" };
const _hoisted_6 = { class: "text-sm font-semibold" };
const _hoisted_7 = { class: "font-normal tracking-wide" };
const _hoisted_8 = {
  class: "text-gray-200 hover:bg-gray-800 hover:text-white text-sm font-semibold rounded-lg cursor-pointer mx-px h-10 flex items-center px-6 py-2",
  href: "https://dmm.injective.network",
  target: "_blank"
};
const _hoisted_9 = { class: "font-normal tracking-wide" };
import {
  getDefaultPerpetualMarketRouteParams,
  getDefaultSpotMarketRouteParams
} from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Mobile",
  setup(__props) {
    const tradeMenuOpen = ref(false);
    const rewardsMenuOpen = ref(false);
    const defaultPerpetualMarketRoute = getDefaultPerpetualMarketRouteParams();
    const defaultSpotMarketRoute = getDefaultSpotMarketRouteParams();
    function handleToggleTradeMenu() {
      tradeMenuOpen.value = !tradeMenuOpen.value;
    }
    function handleToggleRewardsMenu() {
      rewardsMenuOpen.value = !rewardsMenuOpen.value;
    }
    return (_ctx, _cache) => {
      const _component_LayoutNavItem = __nuxt_component_0;
      const _component_AppAccordion = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppAccordion, {
          "is-open": _unref(tradeMenuOpen),
          sm: "",
          "onPanel:toggle": handleToggleTradeMenu
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("navigation.trade")), 1)
          ]),
          content: _withCtx(() => [
            _createVNode(_component_LayoutNavItem, { to: _unref(defaultSpotMarketRoute) }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("navigation.spot")), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_LayoutNavItem, { to: _unref(defaultPerpetualMarketRoute) }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("navigation.perpetual")), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_LayoutNavItem, { to: { name: "convert" } }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("navigation.convert")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["is-open"]),
        _createVNode(_component_AppAccordion, {
          "is-open": _unref(rewardsMenuOpen),
          sm: "",
          "onPanel:toggle": handleToggleRewardsMenu
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("navigation.rewards")), 1)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_LayoutNavItem, { to: { name: "trade-and-earn" } }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("navigation.tradeAndEarn")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("a", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("navigation.dmmProgram")), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["is-open"])
      ]);
    };
  }
});
