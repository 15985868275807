import { default as __nuxt_component_0 } from "/opt/build/repo/components/Asset/Logo.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Layout/Nav/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Layout/Nav/ItemDummy.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Layout/Nav/Item.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Layout/Wallet/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex-1 px-2 lg:px-6 flex justify-end lg:justify-between" };
const _hoisted_2 = { class: "relative h-0 -z-10 w-0 opacity-0 lg:h-full lg:z-0 lg:w-full lg:opacity-100 flex items-center" };
const _hoisted_3 = { class: "flex items-center" };
const _hoisted_4 = ["onClick"];
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Topbar",
  props: {
    isSidebarOpen: Boolean
  },
  emits: ["sidebar:closed", "sidebar:opened"],
  setup(__props, { emit }) {
    const props = __props;
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const ninjaPassStore = useNinjaPassStore();
    const confetti = useConfetti();
    const isUserConnectedProcessCompleted = ref(false);
    const isUserWalletConnected = computed(() => walletStore.isUserWalletConnected);
    const hasNinjaPassCodes = computed(() => {
      if (!ninjaPassStore.codes) {
        return false;
      }
      return ninjaPassStore.codes.length > 0;
    });
    watch(
      () => isUserWalletConnected,
      (newIsUserWalletConnected) => {
        if (!newIsUserWalletConnected) {
          isUserConnectedProcessCompleted.value = false;
        }
      }
    );
    onMounted(() => {
      if (isUserWalletConnected) {
        isUserConnectedProcessCompleted.value = true;
      }
    });
    function handleSidebarToggle() {
      if (props.isSidebarOpen) {
        return emit("sidebar:closed");
      }
      emit("sidebar:opened");
    }
    function handleShowNinjaPassModal() {
      modalStore.openModal({ type: Modal.NinjaPassWinner });
      confetti.showConfetti();
    }
    return (_ctx, _cache) => {
      const _component_AssetLogo = __nuxt_component_0;
      const _component_LayoutNav = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      const _component_LayoutNavItemDummy = __nuxt_component_3;
      const _component_LayoutNavItem = __nuxt_component_4;
      const _component_LayoutWallet = __nuxt_component_5;
      return _openBlock(), _createElementBlock("header", {
        class: _normalizeClass(["w-full z-40 h-12 lg:h-14 bg-gray-1000 flex items-center border-b border-b-gray-900", {
          fixed: __props.isSidebarOpen,
          relative: !__props.isSidebarOpen
        }])
      }, [
        _createElementVNode("div", {
          class: "cursor-pointer pl-6 lg:pr-6 lg:border-r flex items-center",
          onClick: _cache[0] || (_cache[0] = ($event) => _ctx.$router.push({ name: "index" }))
        }, [
          _createVNode(_component_AssetLogo, { alt: "Parallel" })
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_LayoutNav, { class: "hidden lg:block" })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _unref(isUserWalletConnected) && _unref(hasNinjaPassCodes) ? (_openBlock(), _createBlock(_component_LayoutNavItemDummy, {
              key: 0,
              class: "flex px-0 w-10 items-center justify-center",
              onClick: handleShowNinjaPassModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseIcon, {
                  name: "gift",
                  class: "text-white w-4 h-4"
                })
              ]),
              _: 1
            })) : _createCommentVNode("", true),
            _unref(isUserWalletConnected) ? (_openBlock(), _createBlock(_component_LayoutNavItem, {
              key: 1,
              class: "hidden lg:flex",
              "data-cy": "header-activity-link",
              to: { name: "activity" }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("navigation.activity")), 1)
              ]),
              _: 1
            })) : _createCommentVNode("", true),
            _unref(isUserWalletConnected) ? (_openBlock(), _createBlock(_component_LayoutNavItem, {
              key: 2,
              class: "hidden lg:flex",
              "data-cy": "header-account-link",
              to: { name: "account" }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("navigation.account")), 1)
              ]),
              _: 1
            })) : _createCommentVNode("", true),
            _createVNode(_component_LayoutWallet)
          ])
        ]),
        _createElementVNode("button", {
          class: "px-4 border-r border-gray-600 text-white lg:hidden",
          onClick: _withModifiers(handleSidebarToggle, ["stop"])
        }, [
          __props.isSidebarOpen ? (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 0,
            name: "close",
            class: "w-6 h-6"
          })) : (_openBlock(), _createBlock(_component_BaseIcon, {
            key: 1,
            name: "menu",
            class: "w-6 h-6"
          }))
        ], 8, _hoisted_4)
      ], 2);
    };
  }
});
