import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Layout/Wallet/Connect/Wrapper.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { "data-cy": "connect-wallet-popup-keplr-button" };
import { isCosmosWalletInstalled, Wallet } from "@injectivelabs/wallet-ts";
import { WalletConnectStatus } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Keplr",
  setup(__props) {
    const walletStore = useWalletStore();
    const { success } = useNotifications();
    const { $onError } = useNuxtApp();
    const { t } = useLang();
    const isWalletInstalled = isCosmosWalletInstalled(Wallet.Keplr);
    const downloadKeplrLink = ref(null);
    function connect() {
      if (isWalletInstalled) {
        walletStore.connectKeplr().then(() => {
          success({ title: t("connect.successfullyConnected") });
        }).catch((e) => {
          walletStore.setWalletConnectStatus(WalletConnectStatus.disconnected);
          $onError(e);
        });
      } else if (downloadKeplrLink.value && downloadKeplrLink.value.$el) {
        downloadKeplrLink.value.$el.click();
      }
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_LayoutWalletConnectWrapper = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_LayoutWalletConnectWrapper, { onClick: connect }, _createSlots({
        logo: _withCtx(() => [
          _createVNode(_component_BaseIcon, {
            name: "wallet/keplr",
            class: "w-8 h-8"
          })
        ]),
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("connect.keplr")), 1)
        ]),
        description: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("connect.connectUsingKeplr")), 1)
          ])
        ]),
        _: 2
      }, [
        !_unref(isWalletInstalled) ? {
          name: "icon",
          fn: _withCtx(() => [
            _createVNode(_component_NuxtLink, {
              ref_key: "downloadKeplrLink",
              ref: downloadKeplrLink,
              to: "https://www.keplr.app/download",
              target: "_blank",
              rel: "noreferrer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseIcon, {
                  name: "download",
                  class: "h-5 w-5 hover:text-blue-500"
                })
              ]),
              _: 1
            }, 512)
          ]),
          key: "0"
        } : void 0
      ]), 1024);
    };
  }
});
